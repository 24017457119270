import { render, staticRenderFns } from "./RoundCards.vue?vue&type=template&id=17f52879&scoped=true&"
import script from "./RoundCards.vue?vue&type=script&lang=js&"
export * from "./RoundCards.vue?vue&type=script&lang=js&"
import style0 from "./RoundCards.vue?vue&type=style&index=0&id=17f52879&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f52879",
  null
  
)

export default component.exports